import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { showToast, WARNING, withTooltip } from 'summer';
import { GlobalParameter } from '../models/dtos/ger/globalParameter';
import { AvisoMensagens } from '../models/dtos/mic/avisoMensagens';
import Badge from '../views/components/mic/badge/Badge';
import EnumUtil, { EnumWithExtraFields } from './enum.util';

export const columnCenter = {
  headerAlign: () => {
    return 'center';
  },
  style: () => {
    return { textAlign: 'center' };
  },
};

export const tooltipCredenciaisSERPRO = (msg = '') => ({
  tooltipProps: {
    message: `As credenciais de acesso (Consumer Key${msg} e Consumer Secret${msg}) devem ser obtidas a partir da Área do Cliente SERPRO. Esses códigos servem para identificar o usuário e o contrato com o SERPRO`,
  },
});

export const contemAvisoMensagens = (row: AvisoMensagens) => row && row.avisoMensagens && row.avisoMensagens.length > 0;

export const getRowClassAviso = (row: AvisoMensagens) => (contemAvisoMensagens(row) ? 'bg-aviso' : '');

export const fecharDropdown = () => {
  const btnDropdown: any = document.activeElement;
  if (btnDropdown) {
    btnDropdown.click();
  }
};

export const showToastAvisoMensagens = () => {
  showToast('Durante a execução da operação identificamos algumas pendências. Por favor, corrija-as e prossiga com a operação novamente.', WARNING);
};

export const gerarBadge = (list: EnumWithExtraFields[], value: string, isPagamento?: boolean, identificador?: number) => {
  const item = EnumUtil.find(list, value);
  return item ? gerarBadges([item], isPagamento, identificador) : '';
};

export const gerarBadges = (list: EnumWithExtraFields[], isPagamento?: boolean, identificador?: number) =>
  list.map(({ value, label, extraFields: { color, colorIcon, className, icon, tooltipMsg } }, index) => {
    const uniqueId = `tooltip-pagamento-${identificador || 0}-${index}`;

    return isPagamento ? (
      <div key={value} className="d-flex align-items-center font-size-sm">
        <strong style={{ color }}>{label}</strong>
        {icon && (
          <>
            <span id={`id${uniqueId}`}>
              <FontAwesomeIcon icon={icon} color={colorIcon || color} className="ml-2" />
            </span>
            {tooltipMsg && withTooltip({ message: tooltipMsg, placement: 'right' }, uniqueId)}
          </>
        )}
      </div>
    ) : (
      <Badge key={value} backgroundColor={color} className={className} label={label} />
    );
  });

export const isAmbienteOficial = (globalParameter: GlobalParameter) =>
  globalParameter.configuracaoMIC && globalParameter.configuracaoMIC.tipoAmbiente === 'OFICIAL';

export const AvisoConfiguracaoCertificado = () => (
  <>
    <h6 className="font-weight-bold mb-1">Geral (Integra Contador)*</h6>
    <span className="font-size-sm opacity-5">
      Aqui você irá fazer upload do seu certificado digital, bem como o preenchimento obrigatório dos campos de senha, consumer key e consumer secret.
      Isto é necessário para o funcionamento da DCTFWeb, PGDAS, PGMEI, SICALC, SITFIS e Caixa Postal.
    </span>
    <div className="divider mt-1 mb-3" />
  </>
);
