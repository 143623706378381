import React, { FC } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import badgeProAzul from '../../../assets/badgeProAzul.png';
import logoNitrus from '../../../assets/calima_nitrus_icon.svg';
import { BOX, CONNECT, GER, MCG, MCP, MFP, MHO, MIC, MLF, MPR, SITE } from '../../../models/enumerated/ger/ModuloEnum';
import { Reducers } from '../../../store/ducks';
import { GlobalState } from '../../../store/ducks/global.duck';
import ButtonModulo from '../buttonModulo/ButtonModulo';

import './MenuModulos.css';

interface MenuModulosProps {
  mobile?: boolean;
}

const MenuModulos: FC<MenuModulosProps> = ({ mobile }) => {
  const {
    globalParameter: {
      usuario: { administrador, acessoModulos },
      acesso,
    },
  } = useSelector<Reducers, GlobalState>(state => state.globalReducer);

  return (
    <div className="dropdown-mega-menu-md">
      <PerfectScrollbar>
        <div className="d-flex flex-wrap justify-content-center h-100">
          <ButtonModulo
            mobile={mobile}
            enabled={administrador || acessoModulos.ger}
            label="Principal"
            to="/ger/dashboard"
            testId="btnMenuGER"
            className="btn-menu-ger"
            imgSrc={GER.extraFields.icon}
          />
          <ButtonModulo
            mobile={mobile}
            enabled={administrador || acessoModulos.mfp}
            label="Folha de Pagamento"
            to="/mfp/dashboard"
            testId="btnMenuMFP"
            className="btn-menu-mfp"
            imgSrc={MFP.extraFields.icon}
          />
          <ButtonModulo
            mobile={mobile}
            enabled={administrador || acessoModulos.mcg}
            label="Contábil"
            to="/mcg/dashboard"
            testId="btnMenuMCG"
            className="btn-menu-mcg"
            imgSrc={MCG.extraFields.icon}
          />
          <ButtonModulo
            mobile={mobile}
            enabled={administrador || acessoModulos.mlf}
            label="Fiscal"
            to="/mlf/dashboard"
            testId="btnMenuMLF"
            className="btn-menu-mlf"
            imgSrc={MLF.extraFields.icon}
          />
          <ButtonModulo
            mobile={mobile}
            enabled={administrador || acessoModulos.mcp}
            label="Controle Patrimonial"
            to="/mcp/dashboard"
            testId="btnMenuMCP"
            className="btn-menu-mcp"
            imgSrc={MCP.extraFields.icon}
          />
          <ButtonModulo
            mobile={mobile}
            enabled={administrador || acessoModulos.mpr}
            label="Protocolos"
            to="/mpr/dashboard"
            testId="btnMenuMPR"
            className="btn-menu-mpr"
            imgSrc={MPR.extraFields.icon}
          />
          <ButtonModulo
            mobile={mobile}
            enabled={administrador || acessoModulos.mho}
            label={acesso.temAcessoAoNitrus ? 'Nitrus/Honorários' : 'Honorários'}
            to={acesso.temAcessoAoNitrus ? '/mho/dashboardnitrus' : '/mho/dashboard'}
            testId="btnMenuMHO"
            className="btn-menu-mho"
            imgSrc={acesso.temAcessoAoNitrus ? logoNitrus : MHO.extraFields.icon}
          />
          <ButtonModulo
            mobile={mobile}
            enabled={administrador || acessoModulos.connect}
            label="Connect"
            to="/connect/dashboard"
            testId="btnMenuConnect"
            className="btn-menu-connect"
            imgSrc={CONNECT.extraFields.icon}
            calimaPro={!acesso.calimaConnect}
          />
          <ButtonModulo
            mobile={mobile}
            enabled={administrador || acessoModulos.box}
            label="Box"
            to="/box/dashboard"
            testId="btnMenuBox"
            className="btn-menu-box"
            imgSrc={BOX.extraFields.icon}
            calimaPro={!acesso.calimaBox}
          />
          <ButtonModulo
            mobile={mobile}
            enabled={administrador || acessoModulos.site}
            label="Site"
            to="/site/dashboard"
            testId="btnMenuSite"
            className="btn-menu-site"
            imgSrc={SITE.extraFields.icon}
            calimaPro={!acesso.calimaSite}
          />
          <ButtonModulo
            mobile={mobile}
            enabled={administrador || acessoModulos.mic}
            label="Integra Contador"
            to="/mic/dashboard"
            testId="btnMenuMic"
            className="btn-menu-mic"
            imgSrc={MIC.extraFields.icon}
            calimaPro={true}
          />
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default MenuModulos;
