import { createLoadable } from 'summer';

const mfpRoutes = [
  {
    component: createLoadable(() => import('./dashboard/DashboardMFP'), 'DashboardMFP'),
    path: '/mfp/dashboard',
  },
  {
    component: createLoadable(() => import('./grupoEvento/GrupoEventoEditView'), 'GrupoEventoEditView'),
    path: '/mfp/grupoEventoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./grupoEvento/GrupoEventoSearchView'), 'GrupoEventoSearchView'),
    exact: true,
    path: '/mfp/grupoEventoSearchView',
  },
  {
    component: createLoadable(() => import('./lotacaoTributaria/LotacaoTributariaEditView'), 'LotacaoTributariaEditView'),
    path: '/mfp/lotacaoTributariaSearchView/edit',
  },
  {
    component: createLoadable(() => import('./lotacaoTributaria/LotacaoTributariaSearchView'), 'LotacaoTributariaSearchView'),
    exact: true,
    path: '/mfp/lotacaoTributariaSearchView',
  },
  {
    component: createLoadable(() => import('./naturezaRubrica/NaturezaRubricaEditView'), 'NaturezaRubricaEditView'),
    path: '/mfp/naturezaRubricaSearchView/edit',
  },
  {
    component: createLoadable(() => import('./naturezaRubrica/NaturezaRubricaSearchView'), 'NaturezaRubricaSearchView'),
    exact: true,
    path: '/mfp/naturezaRubricaSearchView',
  },
  {
    component: createLoadable(() => import('./banco/BancoEditView'), 'BancoEditView'),
    path: '/mfp/bancoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./banco/BancoSearchView'), 'BancoSearchView'),
    exact: true,
    path: '/mfp/bancoSearchView',
  },
  {
    component: createLoadable(() => import('./customizacaoTermos/CustomizacaoTermosEditView'), 'CustomizacaoTermosEditView'),
    path: '/mfp/customizacaoTermosSearchView/edit',
  },
  {
    component: createLoadable(() => import('./customizacaoTermos/CustomizacaoTermosSearchView'), 'CustomizacaoTermosSearchView'),
    exact: true,
    path: '/mfp/customizacaoTermosSearchView',
  },
  {
    component: createLoadable(() => import('./cbo/CboEditView'), 'CboEditView'),
    path: '/mfp/cboSearchView/edit',
  },
  {
    component: createLoadable(() => import('./cbo/CboSearchView'), 'CboSearchView'),
    exact: true,
    path: '/mfp/cboSearchView',
  },
  {
    component: createLoadable(() => import('./planoSaude/PlanoSaudeEditView'), 'PlanoSaudeEditView'),
    path: '/mfp/planoSaudeSearchView/edit',
  },
  {
    component: createLoadable(() => import('./planoSaude/PlanoSaudeSearchView'), 'PlanoSaudeSearchView'),
    exact: true,
    path: '/mfp/planoSaudeSearchView',
  },
  {
    component: createLoadable(() => import('./instituicaoEnsino/InstituicaoEnsinoEditView'), 'InstituicaoEnsinoEditView'),
    path: '/mfp/instituicaoEnsinoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./instituicaoEnsino/InstituicaoEnsinoSearchView'), 'InstituicaoEnsinoSearchView'),
    exact: true,
    path: '/mfp/instituicaoEnsinoSearchView',
  },
  {
    component: createLoadable(() => import('./rubricahomolognet/RubricaHomolognetEditView'), 'RubricaHomolognetEditView'),
    path: '/mfp/rubricaHomolognetSearchView/edit',
  },
  {
    component: createLoadable(() => import('./rubricahomolognet/RubricaHomolognetSearchView'), 'RubricaHomolognetSearchView'),
    exact: true,
    path: '/mfp/rubricaHomolognetSearchView',
  },
  {
    component: createLoadable(() => import('./valorImpostoPLR/ValorImpostoPLREditView'), 'ValorImpostoPLREditView'),
    path: '/mfp/valorImpostoPLRSearchView/edit',
  },
  {
    component: createLoadable(() => import('./valorImpostoPLR/ValorImpostoPLRSearchView'), 'ValorImpostoPLRSearchView'),
    exact: true,
    path: '/mfp/valorImpostoPLRSearchView',
  },
  {
    component: createLoadable(() => import('./debitoCreditoDarf/DebitoCreditoDarfEditView'), 'DebitoCreditoDarfEditView'),
    path: '/mfp/debitoCreditoDarfSearchView/edit',
  },
  {
    component: createLoadable(() => import('./debitoCreditoDarf/DebitoCreditoDarfSearchView'), 'DebitoCreditoDarfSearchView'),
    exact: true,
    path: '/mfp/debitoCreditoDarfSearchView',
  },
  {
    component: createLoadable(() => import('./codigoGps/CodigoGpsSearchView'), 'CodigoGpsSearchView'),
    exact: true,
    path: '/mfp/codigoGpsSearchView',
  },
  {
    component: createLoadable(() => import('./codigoGps/CodigoGpsEditView'), 'CodigoGpsEditView'),
    path: '/mfp/codigoGpsSearchView/edit',
  },
  {
    component: createLoadable(() => import('./fpas/FpasSearchView'), 'FpasSearchView'),
    exact: true,
    path: '/mfp/fpasSearchView',
  },
  {
    component: createLoadable(() => import('./fpas/FpasEditView'), 'FpasEditView'),
    path: '/mfp/fpasSearchView/edit',
  },
  {
    component: createLoadable(() => import('./previdenciaComplementar/PrevidenciaComplementarSearchView'), 'PrevidenciaComplementarSearchView'),
    exact: true,
    path: '/mfp/previdenciaComplementarSearchView',
  },
  {
    component: createLoadable(() => import('./previdenciaComplementar/PrevidenciaComplementarEditView'), 'PrevidenciaComplementarEditView'),
    path: '/mfp/previdenciaComplementarSearchView/edit',
  },
  {
    component: createLoadable(() => import('./sindicato/SindicatoSearchView'), 'SindicatoSearchView'),
    exact: true,
    path: '/mfp/sindicatoSearchView',
  },
  {
    component: createLoadable(() => import('./sindicato/SindicatoEditView'), 'SindicatoEditView'),
    path: '/mfp/sindicatoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./processoJudicial/ProcessoJudicialSearchView'), 'ProcessoJudicialSearchView'),
    exact: true,
    path: '/mfp/processoJudicialSearchView',
  },
  {
    component: createLoadable(() => import('./processoJudicial/ProcessoJudicialEditView'), 'ProcessoJudicialEditView'),
    path: '/mfp/processoJudicialSearchView/edit',
  },
  {
    component: createLoadable(
      () => import('./infoComplementarProcessoJudicial/InfoComplementarProcessoJudicialSearchView'),
      'InfoComplementarProcessoJudicialSearchView'
    ),
    exact: true,
    path: '/mfp/infoComplementarProcessoJudicialSearchView',
  },
  {
    component: createLoadable(
      () => import('./infoComplementarProcessoJudicial/InfoComplementarProcessoJudicialEditView'),
      'InfoComplementarProcessoJudicialEditView'
    ),
    path: '/mfp/infoComplementarProcessoJudicialSearchView/edit',
  },
  {
    component: createLoadable(() => import('./tomadorServico/TomadorServicoSearchView'), 'TomadorServicoSearchView'),
    exact: true,
    path: '/mfp/tomadorServicoSearchView',
  },
  {
    component: createLoadable(() => import('./tomadorServico/TomadorServicoEditView'), 'TomadorServicoEditView'),
    path: '/mfp/tomadorServicoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./trabalhador/TrabalhadorSearchView'), 'TrabalhadorSearchView'),
    exact: true,
    path: '/mfp/trabalhadorSearchView',
  },
  {
    component: createLoadable(() => import('./trabalhador/TrabalhadorEditView'), 'TrabalhadorEditView'),
    path: '/mfp/trabalhadorSearchView/edit',
  },
  {
    component: createLoadable(() => import('./quadroHorario/QuadroHorarioSearchView'), 'QuadroHorarioSearchView'),
    exact: true,
    path: '/mfp/quadroHorarioSearchView',
  },
  {
    component: createLoadable(() => import('./quadroHorario/QuadroHorarioEditView'), 'QuadroHorarioEditView'),
    exact: true,
    path: '/mfp/quadroHorarioSearchView/edit',
  },
  {
    component: createLoadable(() => import('./evento/EventoSearchView'), 'EventoSearchView'),
    exact: true,
    path: '/mfp/eventoSearchView',
  },
  {
    component: createLoadable(() => import('./evento/EventoEditView'), 'EventoEditView'),
    exact: true,
    path: '/mfp/eventoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./report/ajusteImportacaoManad/AjusteImportacaoManadReportView'), 'AjusteImportacaoManadReportView'),
    exact: true,
    path: '/mfp/ajusteImportacaoManadReportView',
  },
  {
    component: createLoadable(
      () => import('./report/relacaoFuncionario/simples/RelacaoTrabalhadorSimplesReportView'),
      'RelacaoTrabalhadorSimplesReportView'
    ),
    exact: true,
    path: '/mfp/relacaoTrabalhadorSimplesReportView',
  },
  {
    component: createLoadable(
      () => import('./report/relacaoFuncionario/normal/RelacaoTrabalhadorNormalReportView'),
      'RelacaoTrabalhadorNormalReportView'
    ),
    exact: true,
    path: '/mfp/relacaoTrabalhadorNormalReportView',
  },
  {
    component: createLoadable(
      () => import('./report/relacaoFuncionario/completa/RelacaoFuncionarioCompletaReportView'),
      'RelacaoFuncionarioCompletaReportView'
    ),
    exact: true,
    path: '/mfp/relacaoFuncionarioCompletaReportView',
  },
  {
    component: createLoadable(() => import('./report/analiticoGRCSU/AnaliticoGRCSUReportView'), 'AnaliticoGRCSUReportView'),
    exact: true,
    path: '/mfp/analiticoGRCSUReportView',
  },
  {
    component: createLoadable(() => import('./report/conferenciaGRRF/ConferenciaGRRFReportView'), 'ConferenciaGRRFReportView'),
    exact: true,
    path: '/mfp/conferenciaGRRFReportView',
  },
  {
    component: createLoadable(
      () => import('./report/relacaoTomadoresServico/RelacaoTomadoresServicoReportView'),
      'RelacaoTomadoresServicoReportView'
    ),
    exact: true,
    path: '/mfp/relacaoTomadoresServicoReportView',
  },
  {
    component: createLoadable(() => import('./report/sindicatos/SindicatoReportView'), 'SindicatoReportView'),
    exact: true,
    path: '/mfp/sindicatoReportView',
  },
  {
    component: createLoadable(
      () => import('./report/contratosExperienciaPendentes/ContratoExperienciaPendeteReportView'),
      'ContratoExperienciaPendeteReportView'
    ),
    exact: true,
    path: '/mfp/contratoExperienciaPendeteReportView',
  },
  {
    component: createLoadable(() => import('./report/relacaoExamesPendentes/RelacaoExamesPendentesReportView'), 'RelacaoExamesPendentesReportView'),
    exact: true,
    path: '/mfp/relacaoExamesPendentesReportView',
  },
  {
    component: createLoadable(() => import('./report/feriasPendentes/RelacaoFeriasPendentesReportView'), 'RelacaoFeriasPendentesReportView'),
    exact: true,
    path: '/mfp/relacaoFeriasPendentesReportView',
  },
  {
    component: createLoadable(() => import('./report/seguroDesemprego/SeguroDesempregoReportView'), 'SeguroDesempregoReportView'),
    exact: true,
    path: '/mfp/seguroDesempregoReportView',
  },
  {
    component: createLoadable(() => import('./report/ferias/gozadas/FeriasGozadasReportView'), 'FeriasGozadasReportView'),
    exact: true,
    path: '/mfp/feriasGozadasReportView',
  },
  {
    component: createLoadable(() => import('./report/ferias/controle/ControleFeriasReportView'), 'ControleFeriasReportView'),
    exact: true,
    path: '/mfp/controleFeriasReportView',
  },
  {
    component: createLoadable(() => import('./report/ferias/provisao/ProvisaoFeriasReportView'), 'ProvisaoFeriasReportView'),
    exact: true,
    path: '/mfp/provisaoFeriasReportView',
  },
  {
    component: createLoadable(
      () => import('./report/ferias/provisaoAnalitico/ProvisaoFeriasAnaliticoReportView'),
      'ProvisaoFeriasAnaliticaReportView'
    ),
    exact: true,
    path: '/mfp/provisaoFeriasAnaliticoReportView',
  },
  {
    component: createLoadable(() => import('./report/ferias/recibo/ReciboFeriasReportView'), 'ReciboFeriasReportView'),
    exact: true,
    path: '/mfp/reciboFeriasReportView',
  },
  {
    component: createLoadable(() => import('./report/ferias/aviso/AvisoFeriasReportView'), 'AvisoFeriasReportView'),
    exact: true,
    path: '/mfp/avisoFeriasReportView',
  },
  {
    component: createLoadable(() => import('./report/guiaGrfc/GuiaGrfcReportView'), 'GuiaGrfcReportView'),
    exact: true,
    path: '/mfp/guiaGrfcReportView',
  },
  {
    component: createLoadable(() => import('./report/memoriaCalculoDARF/MemoriaCalculoDARFReportView'), 'MemoriaCalculoDARFReportView'),
    exact: true,
    path: '/mfp/memoriaCalculoDARFReportView',
  },
  {
    component: createLoadable(() => import('./report/afastamento/AfastamentoTrabalhadorReportView'), 'AfastamentoTrabalhadorReportView'),
    exact: true,
    path: '/mfp/afastamentoTrabalhadorReportView',
  },
  {
    component: createLoadable(() => import('./report/fichaAnotacoesCTPS/FichaAnotacoesCTPSReportView'), 'FichaAnotacoesCTPSReportView'),
    exact: true,
    path: '/mfp/fichaAnotacoesCTPSReportView',
  },
  {
    component: createLoadable(() => import('./report/cartaRecomendacao/CartaRecomendacaoReportView'), 'CartaRecomendacaoReportView'),
    exact: true,
    path: '/mfp/cartaRecomendacaoReportView',
  },
  {
    component: createLoadable(() => import('./report/cartaOposicao/CartaOposicaoReportView'), 'CartaOposicaoReportView'),
    exact: true,
    path: '/mfp/cartaOposicaoReportView',
  },
  {
    component: createLoadable(() => import('./report/aniversariante/AniversarianteReportView'), 'AniversarianteReportView'),
    exact: true,
    path: '/mfp/aniversarianteReportView',
  },
  {
    component: createLoadable(() => import('./report/ajusteImportacaoRais/AjusteImportacaoRaisReportView'), 'AjusteImportacaoRaisReportView'),
    exact: true,
    path: '/mfp/ajusteImportacaoRaisReportView',
  },
  {
    component: createLoadable(() => import('./report/ajusteImportacaoSefip/AjusteImportacaoSefipReportView'), 'AjusteImportacaoSefipReportView'),
    exact: true,
    path: '/mfp/ajusteImportacaoSefipReportView',
  },
  {
    component: createLoadable(() => import('./report/registroEmpregado/RegistroEmpregadoReportView'), 'RegistroEmpregadoReportView'),
    exact: true,
    path: '/mfp/registroEmpregadoReportView',
  },
  {
    component: createLoadable(() => import('./report/acordoCompensacaoHoras/AcordoCompensacaoHorasReportView'), 'AcordoCompensacaoHorasReportView'),
    exact: true,
    path: '/mfp/acordoCompensacaoHorasReportView',
  },
  {
    component: createLoadable(() => import('./report/termoAditivo/TermoAditivoReportView'), 'TermoAditivoReportView'),
    exact: true,
    path: '/mfp/termoAditivoReportView',
  },
  {
    component: createLoadable(
      () => import('./report/termoAditivoTransferencia/TermoAditivoTransferenciaReportView'),
      'TermoAditivoTransferenciaReportView'
    ),
    exact: true,
    path: '/mfp/termoAditivoTransferenciaReportView',
  },
  {
    component: createLoadable(() => import('./report/analiseRais/AnaliseRaisReportView'), 'AnaliseRaisReportView'),
    exact: true,
    path: '/mfp/analiseRaisReportView',
  },
  {
    component: createLoadable(() => import('./report/abonoPecuniario/AbonoPecuniarioReportView'), 'AbonoPecuniarioReportView'),
    exact: true,
    path: '/mfp/abonoPecuniarioReportView',
  },
  {
    component: createLoadable(() => import('./vinculo/VinculoSearchView'), 'VinculoSearchView'),
    exact: true,
    path: '/mfp/vinculoSearchView',
  },
  {
    component: createLoadable(() => import('./vinculo/VinculoEditView'), 'VinculoEditView'),
    path: '/mfp/vinculoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./pagamentoCooperativa/PagamentoCooperativaEditView'), 'PagamentoCooperativaEditView'),
    path: '/mfp/pagamentoCooperativaSearchView/edit',
  },
  {
    component: createLoadable(() => import('./pagamentoCooperativa/PagamentoCooperativaSearchView'), 'PagamentoCooperativaSearchView'),
    exact: true,
    path: '/mfp/pagamentoCooperativaSearchView',
  },
  {
    component: createLoadable(() => import('./atestado/AtestadoEditView'), 'AtestadoEditView'),
    path: '/mfp/atestadoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./atestado/AtestadoSearchView'), 'AtestadoSearchView'),
    exact: true,
    path: '/mfp/atestadoSearchView',
  },
  {
    component: createLoadable(() => import('./agencia/AgenciaEditView'), 'AgenciaEditView'),
    path: '/mfp/agenciaSearchView/edit',
  },
  {
    component: createLoadable(() => import('./agencia/AgenciaSearchView'), 'AgenciaSearchView'),
    exact: true,
    path: '/mfp/agenciaSearchView',
  },
  {
    component: createLoadable(() => import('./valorImposto/ValorImpostoEditView'), 'ValorImpostoEditView'),
    path: '/mfp/valorImpostoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./valorImposto/ValorImpostoSearchView'), 'ValorImpostoSearchView'),
    exact: true,
    path: '/mfp/valorImpostoSearchView',
  },
  {
    component: createLoadable(() => import('./producaoRural/ProducaoRuralEditView'), 'ProducaoRuralEditView'),
    path: '/mfp/producaoRuralSearchView/edit',
  },
  {
    component: createLoadable(() => import('./producaoRural/ProducaoRuralSearchView'), 'ProducaoRuralSearchView'),
    exact: true,
    path: '/mfp/producaoRuralSearchView',
  },
  {
    component: createLoadable(() => import('./responsavelMenorAprendiz/ResponsavelMenorAprendizEditView'), 'ResponsavelMenorAprendizEditView'),
    path: '/mfp/responsavelMenorAprendizSearchView/edit',
  },
  {
    component: createLoadable(() => import('./responsavelMenorAprendiz/ResponsavelMenorAprendizSearchView'), 'ResponsavelMenorAprendizSearchView'),
    exact: true,
    path: '/mfp/responsavelMenorAprendizSearchView',
  },
  {
    component: createLoadable(
      () => import('./fatorRiscoAmbienteTrabalho/FatorRiscoAmbienteTrabalhoSearchView'),
      'FatorRiscoAmbienteTrabalhoSearchView'
    ),
    exact: true,
    path: '/mfp/fatorRiscoAmbienteTrabalhoSearchView',
  },
  {
    component: createLoadable(() => import('./ambienteTrabalho/AmbienteTrabalhoEditView'), 'AmbienteTrabalhoEditView'),
    path: '/mfp/ambienteTrabalhoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./ambienteTrabalho/AmbienteTrabalhoSearchView'), 'AmbienteTrabalhoSearchView'),
    exact: true,
    path: '/mfp/ambienteTrabalhoSearchView',
  },
  {
    component: createLoadable(() => import('./cargo/CargoEditView'), 'CargoEditView'),
    path: '/mfp/cargoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./cargo/CargoSearchView'), 'CargoSearchView'),
    exact: true,
    path: '/mfp/cargoSearchView',
  },
  {
    component: createLoadable(() => import('./ppp/PPPEditView'), 'PPPEditView'),
    path: '/mfp/pppSearchView/edit',
  },
  {
    component: createLoadable(() => import('./ppp/PPPSearchView'), 'PPPSearchView'),
    exact: true,
    path: '/mfp/pppSearchView',
  },
  {
    component: createLoadable(() => import('./avisoPrevioDias/AvisoPrevioDiasEditView'), 'AvisoPrevioDiasEditView'),
    path: '/mfp/avisoPrevioDiasSearchView/edit',
  },
  {
    component: createLoadable(() => import('./avisoPrevioDias/AvisoPrevioDiasSearchView'), 'AvisoPrevioDiasSearchView'),
    exact: true,
    path: '/mfp/avisoPrevioDiasSearchView',
  },
  {
    component: createLoadable(() => import('./report/qualificacaoCadastral/QualificacaoCadastralReportView'), 'QualificacaoCadastralReportView'),
    path: '/mfp/qualificacaoCadastralReportView',
  },
  {
    component: createLoadable(() => import('./valeTransporte/ValeTransporteSearchView'), 'ValeTransporteSearchView'),
    exact: true,
    path: '/mfp/valeTransporteSearchView',
  },
  {
    component: createLoadable(() => import('./valeTransporte/ValeTransporteEditView'), 'ValeTransporteEditView'),
    exact: true,
    path: '/mfp/valeTransporteSearchView/edit',
  },
  {
    component: createLoadable(
      () => import('./report/salariofamilia/termoResponsabilidade/TermoResponsabilidadeReportView'),
      'TermoResponsabilidadeReportView'
    ),
    path: '/mfp/termoResponsabilidadeReportView',
  },
  {
    component: createLoadable(
      () => import('./report/reciboEntregaDevolucaoCtps/ReciboEntregaDevolucaoCtpsReportView'),
      'ReciboEntregaDevolucaoCtpsReportView'
    ),
    exact: true,
    path: '/mfp/reciboEntregaDevolucaoCtpsReportView',
  },
  {
    component: createLoadable(() => import('./report/reciboCestaBasica/ReciboCestaBasicaReportView'), 'ReciboCestaBasicaReportView'),
    exact: true,
    path: '/mfp/reciboCestaBasicaReportView',
  },
  {
    component: createLoadable(() => import('./report/valetransporte/recibo/ValeTransporteReciboReportView'), 'ValeTransporteReciboReportView'),
    exact: true,
    path: '/mfp/valeTransporteReciboReportView',
  },
  {
    component: createLoadable(() => import('./feriado/FeriadoSearchView'), 'FeriadoSearchView'),
    exact: true,
    path: '/mfp/feriadoSearchView',
  },
  {
    component: createLoadable(() => import('./feriado/FeriadoEditView'), 'FeriadoEditView'),
    path: '/mfp/feriadoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./report/avisoPrevio/AvisoPrevioReportView'), 'AvisoPrevioReportView'),
    exact: true,
    path: '/mfp/avisoPrevioReportView',
  },
  {
    component: createLoadable(() => import('./report/declaracaoOpcaoFgts/DeclaracaoOpcaoFgtsReportView'), 'DeclaracaoOpcaoFgtsReportView'),
    exact: true,
    path: '/mfp/declaracaoOpcaoFgtsReportView',
  },
  {
    component: createLoadable(() => import('./report/reciboValeRefeicao/ValeRefeicaoReciboReportView'), 'ValeRefeicaoReciboReportView'),
    exact: true,
    path: '/mfp/valeRefeicaoReciboReportView',
  },
  {
    component: createLoadable(() => import('./afastamento/AfastamentoEditView'), 'AfastamentoEditView'),
    exact: true,
    path: '/mfp/afastamentoEditView',
  },
  {
    component: createLoadable(() => import('./vinculoTomadorServico/VinculoTomadorServicoEditView'), 'VinculoTomadorServicoEditView'),
    exact: true,
    path: '/mfp/vinculoTomadorServicoEditView',
  },
  {
    component: createLoadable(() => import('./ferias/FeriasEditView'), 'FeriasEditView'),
    exact: true,
    path: '/mfp/feriasEditView',
  },
  {
    component: createLoadable(() => import('./rescisao/RescisaoEditView'), 'RescisaoEditView'),
    exact: true,
    path: '/mfp/rescisaoEditView',
  },
  {
    component: createLoadable(() => import('./vinculoEvento/VinculoEventoEditView'), 'VinculoEventoEditView'),
    exact: true,
    path: '/mfp/vinculoEventoEditView',
  },
  {
    component: createLoadable(
      () => import('./report/acordoProrrogacaoHoraExtra/AcordoProrrogacaoHoraExtraReportView'),
      'AcordoProrrogacaoHoraExtraReportView'
    ),
    exact: true,
    path: '/mfp/acordoProrrogacaoHoraExtraReportView',
  },
  {
    component: createLoadable(() => import('./report/cartaSuspensao/CartaSuspensaoReportView'), 'CartaSuspensaoReportView'),
    exact: true,
    path: '/mfp/cartaSuspensaoReportView',
  },
  {
    component: createLoadable(
      () => import('./contribuicaoSindicalPatronal/ContribuicaoSindicalPatronalEditView'),
      'ContribuicaoSindicalPatronalEditView'
    ),
    path: '/mfp/contribuicaoSindicalPatronalSearchView/edit',
  },
  {
    component: createLoadable(
      () => import('./contribuicaoSindicalPatronal/ContribuicaoSindicalPatronalSearchView'),
      'ContribuicaoSindicalPatronalSearchView'
    ),
    exact: true,
    path: '/mfp/contribuicaoSindicalPatronalSearchView',
  },
  {
    component: createLoadable(() => import('./empresaEsocialConfig/EmpresaEsocialConfigView'), 'EmpresaEsocialConfigView'),
    exact: true,
    path: '/mfp/empresaEsocialConfigView',
  },
  {
    component: createLoadable(() => import('./report/cartaPreposicao/CartaPreposicaoReportView'), 'CartaPreposicaoReportView'),
    exact: true,
    path: '/mfp/cartaPreposicaoReportView',
  },
  {
    component: createLoadable(() => import('./report/fichaFinanceira/FichaFinanceiraReportView'), 'FichaFinanceiraReportView'),
    exact: true,
    path: '/mfp/fichaFinanceiraReportView',
  },
  {
    component: createLoadable(() => import('./report/folhaPresenca/FolhaPresencaReportView'), 'FolhaPresencaReportView'),
    exact: true,
    path: '/mfp/folhaPresencaReportView',
  },
  {
    component: createLoadable(() => import('./report/horarioTrabalho/HorarioTrabalhoReportView'), 'HorarioTrabalhoReportView'),
    exact: true,
    path: '/mfp/horarioTrabalhoReportView',
  },
  {
    component: createLoadable(() => import('./report/contraCheque/ContraChequeReportView'), 'ContraChequeReportView'),
    exact: true,
    path: '/mfp/contraChequeReportView',
  },
  {
    component: createLoadable(() => import('./calculoReferencia/CalculoReferenciaSearchView'), 'CalculoReferenciaSearchView'),
    exact: true,
    path: '/mfp/calculoReferenciaSearchView',
  },
  {
    component: createLoadable(() => import('./report/rpa/RpaReportView'), 'RpaReportView'),
    exact: true,
    path: '/mfp/rpaReportView',
  },
  {
    component: createLoadable(() => import('./report/reciboProLabore/ReciboProLaboreReportView'), 'ReciboProLaboreReportView'),
    exact: true,
    path: '/mfp/reciboProLaboreReportView',
  },
  {
    component: createLoadable(() => import('./report/ficha/FichaSalarioFamiliaReportView'), 'FichaSalarioFamiliaReportView'),
    exact: true,
    path: '/mfp/fichaSalarioFamiliaReportView',
  },
  {
    component: createLoadable(() => import('./report/relacao/SalarioFamiliaReportView'), 'SalarioFamiliaReportView'),
    exact: true,
    path: '/mfp/salarioFamiliaReportView',
  },
  {
    component: createLoadable(
      () => import('./report/admitidosDemitidosPeriodo/AdmitidosDemitidosPeriodoReportView'),
      'AdmitidosDemitidosPeriodoReportView'
    ),
    exact: true,
    path: '/mfp/admitidosDemitidosPeriodoReportView',
  },
  {
    component: createLoadable(() => import('./report/nis/NisReportView'), 'NisReportView'),
    exact: true,
    path: '/mfp/nisReportView',
  },
  {
    component: createLoadable(() => import('./exportacaoManad/ExportacaoManadProcessView'), 'ExportacaoManadProcessView'),
    exact: true,
    path: '/mfp/exportacaoManadProcessView',
  },
  {
    component: createLoadable(() => import('./exportacaoRais/ExportacaoRaisProcessView'), 'ExportacaoRaisProcessView'),
    exact: true,
    path: '/mfp/exportacaoRaisProcessView',
  },
  {
    component: createLoadable(() => import('./exportacaoDirf/ExportacaoDirfProcessView'), 'ExportacaoDirfProcessView'),
    exact: true,
    path: '/mfp/exportacaoDirfProcessView',
  },
  {
    component: createLoadable(() => import('./exportacaoCaged/ExportacaoCagedProcessView'), 'ExportacaoCagedProcessView'),
    exact: true,
    path: '/mfp/exportacaoCagedProcessView',
  },
  {
    component: createLoadable(
      () => import('./report/inss/salarioContribuicao/RelacaoSalarioContribuicaoReportView'),
      'RelacaoSalarioContribuicaoReportView'
    ),
    exact: true,
    path: '/mfp/relacaoSalarioContribuicaoReportView',
  },
  {
    component: createLoadable(() => import('./report/relacaoFaltas/RelacaoFaltasTrabalhadorReportView'), 'RelacaoFaltasTrabalhadorReportView'),
    exact: true,
    path: '/mfp/relacaoFaltasTrabalhadorReportView',
  },
  {
    component: createLoadable(() => import('./compensacao/CompensacaoSearchView'), 'CompensacaoSearchView'),
    exact: true,
    path: '/mfp/compensacaoSearchView',
  },
  {
    component: createLoadable(() => import('./compensacao/CompensacaoEditView'), 'CompensacaoEditView'),
    path: '/mfp/compensacaoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./gpsDiversosAnexos/GpsDiversosAnexosSearchView'), 'GpsDiversosAnexosSearchView'),
    exact: true,
    path: '/mfp/gpsDiversosAnexosSearchView',
  },
  {
    component: createLoadable(() => import('./gpsDiversosAnexos/GpsDiversosAnexosEditView'), 'GpsDiversosAnexosEditView'),
    path: '/mfp/gpsDiversosAnexosSearchView/edit',
  },
  {
    component: createLoadable(() => import('./report/valoresPagosGps/ValoresPagosGpsReportView'), 'ValoresPagosGpsReportView'),
    exact: true,
    path: '/mfp/valoresPagosGpsReportView',
  },
  {
    component: createLoadable(() => import('./guiaGps/GuiaGpsSearchView'), 'GuiaGpsSearchView'),
    exact: true,
    path: '/mfp/guiaGpsSearchView',
  },
  {
    component: createLoadable(() => import('./guiaGps/GuiaGpsEditView'), 'GuiaGpsEditView'),
    path: '/mfp/guiaGpsSearchView/edit',
  },
  {
    component: createLoadable(
      () => import('./report/declaracaoOutroVinculoEmpregaticio/DeclaracaoOutroVinculoEmpregaticioReportView'),
      'DeclaracaoOutroVinculoEmpregaticioReportView'
    ),
    exact: true,
    path: '/mfp/declaracaoOutroVinculoEmpregaticioReportView',
  },
  {
    component: createLoadable(() => import('./report/folhaResumida/FolhaResumidaReportView'), 'FolhaResumidaReportView'),
    exact: true,
    path: '/mfp/folhaResumidaReportView',
  },
  {
    component: createLoadable(() => import('./report/planilhaDepositoFgts/PlanilhaDepositoFgtsReportView'), 'PlanilhaDepositoFgtsReportView'),
    exact: true,
    path: '/mfp/planilhaDepositoFgtsReportView',
  },
  {
    component: createLoadable(() => import('./report/guiaGps/GuiaGpsReportView'), 'GuiaGpsReportView'),
    exact: true,
    path: '/mfp/guiaGpsReportView',
  },
  {
    component: createLoadable(() => import('./report/resumoFolha/ResumoFolhaReportView'), 'ResumoFolhaReportView'),
    exact: true,
    path: '/mfp/resumoFolhaReportView',
  },
  {
    component: createLoadable(() => import('./report/contaBancariaPagamento/ContaBancariaPagamentoReportView'), 'ContaBancariaPagamentoReportView'),
    exact: true,
    path: '/mfp/contaBancariaPagamentoReportView',
  },
  {
    component: createLoadable(() => import('./report/analiticoRescisao/AnaliticoRescisaoReportView'), 'AnaliticoRescisaoReportView'),
    exact: true,
    path: '/mfp/analiticoRescisaoReportView',
  },
  {
    component: createLoadable(
      () => import('./report/atestadoSaudeOcupacional/AtestadoSaudeOcupacionalReportView'),
      'AtestadoSaudeOcupacionalReportView'
    ),
    exact: true,
    path: '/mfp/atestadoSaudeOcupacionalReportView',
  },
  {
    component: createLoadable(() => import('./report/comprovanteRendimento/ComprovanteRendimentoReportView'), 'ComprovanteRendimentoReportView'),
    exact: true,
    path: '/mfp/comprovanteRendimentoReportView',
  },
  {
    component: createLoadable(() => import('./report/provisaoDecimoTerceiro/ProvisaoDecimoTerceiroReportView'), 'ProvisaoDecimoTerceiroReportView'),
    exact: true,
    path: '/mfp/provisaoDecimoTerceiroReportView',
  },
  {
    component: createLoadable(
      () => import('./report/declaracaoFamiliaImpostoRenda/DeclaracaoFamiliaImpostoRendaReportView'),
      'DeclaracaoFamiliaImpostoRendaReportView'
    ),
    exact: true,
    path: '/mfp/declaracaoFamiliaImpostoRendaReportView',
  },
  {
    component: createLoadable(
      () => import('./report/dispensaTerminoContrato/DispensaTerminoContratoReportView'),
      'DispensaTerminoContratoReportView'
    ),
    exact: true,
    path: '/mfp/dispensaTerminoContratoReportView',
  },
  {
    component: createLoadable(() => import('./report/folhaAnalitica/FolhaAnaliticaReportView'), 'FolhaAnaliticaReportView'),
    exact: true,
    path: '/mfp/folhaAnaliticaReportView',
  },
  {
    component: createLoadable(() => import('./report/relacaoEvento/RelacaoEventoReportView'), 'RelacaoEventoReportView'),
    exact: true,
    path: '/mfp/relacaoEventoReportView',
  },
  {
    component: createLoadable(() => import('./report/sefip/SefipReportView'), 'SefipReportView'),
    exact: true,
    path: '/mfp/sefipReportView',
  },
  {
    component: createLoadable(
      () => import('./report/etiqueta/carteiraTrabalho/EtiquetaCarteiraTrabalhoReportView'),
      'EtiquetaCarteiraTrabalhoReportView'
    ),
    exact: true,
    path: '/mfp/etiquetaCarteiraTrabalhoReportView',
  },
  {
    component: createLoadable(() => import('./report/etiqueta/cartaoPonto/EtiquetaCartaoPontoReportView'), 'EtiquetaCartaoPontoReportView'),
    exact: true,
    path: '/mfp/etiquetaCartaoPontoReportView',
  },
  {
    component: createLoadable(
      () => import('./report/etiqueta/ctpsAvisoProjecaoAvisoPrevioIndenizado/EtiquetaCTPSAvisoProjecaoAvisoPrevioIndenizadoReportView'),
      'EtiquetaCTPSAvisoProjecaoAvisoPrevioIndenizadoReportView'
    ),
    exact: true,
    path: '/mfp/ctpsAvisoProjecaoAvisoPrevioIndenizadoReportView',
  },
  {
    component: createLoadable(() => import('./report/inss/beneficioIncapacidade/BeneficioIncapacidadeReportView'), 'BeneficioIncapacidadeReportView'),
    exact: true,
    path: '/mfp/beneficioIncapacidadeReportView',
  },
  {
    component: createLoadable(() => import('./report/memoriaCalculo/vinculo/MemoriaCalculoVinculoReportView'), 'MemoriaCalculoVinculoReportView'),
    exact: true,
    path: '/mfp/memoriaCalculoVinculoReportView',
  },
  {
    component: createLoadable(() => import('./report/memoriaCalculo/gps/MemoriaCalculoGpsReportView'), 'MemoriaCalculoGpsReportView'),
    exact: true,
    path: '/mfp/memoriaCalculoGpsReportView',
  },
  {
    component: createLoadable(
      () => import('./report/memoriaCalculo/maiorRemuneracao/MemoriaCalculoMaiorRemuneracaoReportView'),
      'MemoriaCalculoMaiorRemuneracaoReportView'
    ),
    exact: true,
    path: '/mfp/memoriaCalculoMaiorRemuneracaoReportView',
  },
  {
    component: createLoadable(
      () => import('./report/reciboParticipacaoLucro/ReciboParticipacaoLucroReportView'),
      'ReciboParticipacaoLucroReportView'
    ),
    exact: true,
    path: '/mfp/reciboParticipacaoLucroReportView',
  },
  {
    component: createLoadable(() => import('./report/pensionistas/PensionistasReportView'), 'PensionistasReportView'),
    exact: true,
    path: '/mfp/pensionistasReportView',
  },
  {
    component: createLoadable(
      () => import('./report/relacaoFuncionario/comTomador/RelacaoFuncionarioTomadorReportView'),
      'RelacaoFuncionarioTomadorReportView'
    ),
    exact: true,
    path: '/mfp/relacaoFuncionarioTomadorReportView',
  },
  {
    component: createLoadable(
      () => import('./report/relacaoEventoTrabalhador/RelacaoEventoTrabalhadorReportView'),
      'RelacaoEventoTrabalhadorReportView'
    ),
    exact: true,
    path: '/mfp/relacaoEventoTrabalhadorReportView',
  },
  {
    component: createLoadable(() => import('./report/quitacaoAnual/QuitacaoAnualReportView'), 'QuitacaoAnualReportView'),
    exact: true,
    path: '/mfp/quitacaoAnualReportView',
  },
  {
    component: createLoadable(
      () => import('./report/valetransporte/controleIndividual/ValeTransporteControleIndividualReportView'),
      'ValeTransporteControleIndividualReportView'
    ),
    exact: true,
    path: '/mfp/valeTransporteControleIndividualReportView',
  },
  {
    component: createLoadable(
      () => import('./report/valetransporte/registroParticipacao/RegistroParticipacaoReportView'),
      'RegistroParticipacaoReportView'
    ),
    exact: true,
    path: '/mfp/registroParticipacaoReportView',
  },
  {
    component: createLoadable(
      () => import('./report/valetransporte/desistencia/ValeTransporteDesistenciaReportView'),
      'ValeTransporteDesistenciaReportView'
    ),
    exact: true,
    path: '/mfp/valeTransporteDesistenciaReportView',
  },
  {
    component: createLoadable(() => import('./cat/CatSearchView'), 'CatSearchView'),
    exact: true,
    path: '/mfp/catSearchView',
  },
  {
    component: createLoadable(() => import('./cat/CatEditView'), 'CatEditView'),
    path: '/mfp/catSearchView/edit',
  },
  {
    component: createLoadable(() => import('./report/cat/CatReportView'), 'CatReportView'),
    exact: true,
    path: '/mfp/catReportView',
  },
  {
    component: createLoadable(() => import('./configDarf/ConfigDarfSearchView'), 'ConfigDarfSearchView'),
    exact: true,
    path: '/mfp/configDarfSearchView',
  },
  {
    component: createLoadable(() => import('./configDarf/ConfigDarfEditView'), 'ConfigDarfEditView'),
    path: '/mfp/configDarfSearchView/edit',
  },
  {
    component: createLoadable(() => import('./pagamentoTomadorServico/PagamentoTomadorServicoSearchView'), 'PagamentoTomadorServicoSearchView'),
    exact: true,
    path: '/mfp/pagamentoTomadorServicoSearchView',
  },
  {
    component: createLoadable(() => import('./pagamentoTomadorServico/PagamentoTomadorServicoEditView'), 'PagamentoTomadorServicoEditView'),
    path: '/mfp/pagamentoTomadorServicoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./feriasDeducaoFaltas/FeriasDeducaoFaltasSearchView'), 'FeriasDeducaoFaltasSearchView'),
    exact: true,
    path: '/mfp/feriasDeducaoFaltasSearchView',
  },
  {
    component: createLoadable(() => import('./feriasDeducaoFaltas/FeriasDeducaoFaltasEditView'), 'FeriasDeducaoFaltasEditView'),
    path: '/mfp/feriasDeducaoFaltasSearchView/edit',
  },
  {
    component: createLoadable(
      () => import('./feriasDeducaoFaltasProporcionais/FeriasDeducaoFaltasProporcionaisSearchView'),
      'FeriasDeducaoFaltasProporcionaisSearchView'
    ),
    exact: true,
    path: '/mfp/feriasDeducaoFaltasProporcionaisSearchView',
  },
  {
    component: createLoadable(() => import('./sefip/SefipProcessView'), 'SefipProcessView'),
    exact: true,
    path: '/mfp/sefipProcessView',
  },
  {
    component: createLoadable(() => import('./nis/NisProcessView'), 'NisProcessView'),
    exact: true,
    path: '/mfp/nisProcessView',
  },
  {
    component: createLoadable(() => import('./seguroDesemprego/SeguroDesempregoProcessView'), 'SeguroDesempregoProcessView'),
    exact: true,
    path: '/mfp/seguroDesempregoProcessView',
  },
  {
    component: createLoadable(() => import('./vinculo/VinculoSearchProcessView'), 'VinculoSearchProcessView'),
    exact: true,
    path: '/mfp/vinculoSearchProcessView',
  },
  {
    component: createLoadable(() => import('./integracaoContabil/contabilizacaoDARF/ContabilizacaoDARFSearchView'), 'ContabilizacaoDARFSearchView'),
    exact: true,
    path: '/mfp/contabilizacaoDARFSearchView',
  },
  {
    component: createLoadable(() => import('./integracaoContabil/contabilizacaoDARF/ContabilizacaoDARFEditView'), 'ContabilizacaoDARFEditView'),
    path: '/mfp/contabilizacaoDARFSearchView/edit',
  },
  {
    component: createLoadable(
      () => import('./integracaoContabil/contabilizacaoEventoMFP/ContabilizacaoEventoMFPSearchView'),
      'ContabilizacaoEventoMFPSearchView'
    ),
    exact: true,
    path: '/mfp/contabilizacaoEventoMFPSearchView',
  },
  {
    component: createLoadable(
      () => import('./integracaoContabil/contabilizacaoEventoMFP/ContabilizacaoEventoMFPEditView'),
      'ContabilizacaoEventoMFPEditView'
    ),
    path: '/mfp/contabilizacaoEventoMFPSearchView/edit',
  },
  {
    component: createLoadable(() => import('./integracaoContabil/contabilizacaoGPS/ContabilizacaoGPSSearchView'), 'ContabilizacaoGPSSearchView'),
    exact: true,
    path: '/mfp/contabilizacaoGPSSearchView',
  },
  {
    component: createLoadable(() => import('./integracaoContabil/contabilizacaoGPS/ContabilizacaoGPSEditView'), 'ContabilizacaoGPSEditView'),
    path: '/mfp/contabilizacaoGPSSearchView/edit',
  },
  {
    component: createLoadable(
      () => import('./integracaoContabil/contabilizacaoProvisao/ContabilizacaoProvisaoEditView'),
      'ContabilizacaoProvisaoEditView'
    ),
    exact: true,
    path: '/mfp/contabilizacaoProvisaoEditView',
  },
  {
    component: createLoadable(() => import('./integracaoContabil/contabilizacaoGRRF/ContabilizacaoGRRFEditView'), 'ContabilizacaoGRRFEditView'),
    exact: true,
    path: '/mfp/contabilizacaoGRRFEditView',
  },
  {
    component: createLoadable(
      () => import('./integracaoContabil/importarIntegracaoFolhaContabil/ImportarIntegracaoFolhaContabilProcessView'),
      'ImportarIntegracaoFolhaContabilProcessView'
    ),
    exact: true,
    path: '/mfp/importarIntegracaoFolhaContabilProcessView',
  },
  {
    component: createLoadable(() => import('./report/contratosTrabalho/ContratosTrabalhoReportView'), 'ContratosTrabalhoReportView'),
    exact: true,
    path: '/mfp/contratosTrabalhoReportView',
  },
  {
    component: createLoadable(() => import('./report/guiaGrcsu/GuiaGrcsuReportView'), 'GuiaGrcsuReportView'),
    exact: true,
    path: '/mfp/guiaGrcsuReportView',
  },
  {
    component: createLoadable(() => import('./report/guiaDarf/GuiaDarfReportView'), 'GuiaDarfReportView'),
    exact: true,
    path: '/mfp/guiaDarfReportView',
  },
  {
    component: createLoadable(() => import('./report/ppp/PppReportView'), 'PppReportView'),
    exact: true,
    path: '/mfp/pppReportView',
  },
  {
    component: createLoadable(() => import('./report/epi/EpiReportView'), 'EpiReportView'),
    exact: true,
    path: '/mfp/epiReportView',
  },
  {
    component: createLoadable(() => import('./epi/EpiSearchView'), 'EpiSearchView'),
    exact: true,
    path: '/mfp/epiSearchView',
  },
  {
    component: createLoadable(() => import('./epi/EpiEditView'), 'EpiEditView'),
    path: '/mfp/epiSearchView/edit',
  },
  {
    component: createLoadable(() => import('../ger/forcli/ForcliSearchView'), 'ForcliSearchView'),
    exact: true,
    path: '/mfp/forcliSearchView',
  },
  {
    component: createLoadable(() => import('../ger/forcli/ForcliEditView'), 'ForcliEditView'),
    path: '/mfp/forcliSearchView/edit',
  },
  {
    component: createLoadable(() => import('../mcg/centroCusto/CentroCustoSearchView'), 'CentroCustoSearchView'),
    exact: true,
    path: '/mfp/centroCustoSearchView',
  },
  {
    component: createLoadable(() => import('../mcg/centroCusto/CentroCustoEditView'), 'CentroCustoEditView'),
    path: '/mfp/centroCustoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./configEmpresa/ConfigEmpresaEditView'), 'ConfigEmpresaEditView'),
    path: '/mfp/configEmpresaEditView',
  },
  {
    component: createLoadable(() => import('./importacaoNis/ImportacaoNisProcessView'), 'ImportacaoNisProcessView'),
    exact: true,
    path: '/mfp/importacaoNisProcessView',
  },
  {
    component: createLoadable(
      () => import('./importacaoPontoEletronico/ImportacaoPontoEletronicoProcessView'),
      'ImportacaoPontoEletronicoProcessView'
    ),
    exact: true,
    path: '/mfp/importacaoPontoEletronicoProcessView',
  },
  {
    component: createLoadable(() => import('./importacaoDOS/ImportacaoDOSProcessView'), 'ImportacaoDOSProcessView'),
    exact: true,
    path: '/mfp/importacaoDOSProcessView',
  },
  {
    component: createLoadable(() => import('./exportacaoBEM/BEMExportacaoProcessView'), 'BEMExportacaoProcessView'),
    exact: true,
    path: '/mfp/bemExportacaoProcessView',
  },
  {
    component: createLoadable(() => import('./exportacaoContraCheque/ExportacaoContraChequeSearchView'), 'ExportacaoContraChequeSearchView'),
    exact: true,
    path: '/mfp/exportacaoContraChequeSearchView',
  },
  {
    component: createLoadable(() => import('./exportacaoContraCheque/ExportacaoContraChequeEditView'), 'ExportacaoContraChequeEditView'),
    exact: true,
    path: '/mfp/exportacaoContraChequeSearchView/edit',
  },
  {
    component: createLoadable(() => import('./report/relacaoCargoSalario/RelacaoCargosSalariosReportView'), 'RelacaoCargosSalariosReportView'),
    exact: true,
    path: '/mfp/relacaoCargosSalariosReportView',
  },
  {
    component: createLoadable(() => import('./fechamentoRestauracao/FechamentoRestauracaoMFPProcessView'), 'FechamentoRestauracaoMFPProcessView'),
    exact: true,
    path: '/mfp/fechamentoRestauracaoMFPProcessView',
  },
  {
    component: createLoadable(() => import('../mlf/guiaAvulsa/GuiaAvulsaSearchView'), 'GuiaAvulsaSearchView'),
    exact: true,
    path: '/mfp/guiaAvulsaSearchView',
  },
  {
    component: createLoadable(() => import('../mlf/guiaAvulsa/GuiaAvulsaEditView'), 'GuiaAvulsaEditView'),
    exact: true,
    path: '/mfp/guiaAvulsaSearchView/edit',
  },
  {
    component: createLoadable(() => import('./integracaoGrupoEvento/IntegracaoGrupoEventoProcessView'), 'IntegracaoGrupoEventoProcessView'),
    exact: true,
    path: '/mfp/integracaoGrupoEventoProcessView',
  },
  {
    component: createLoadable(() => import('./dependente/DependenteSearchView'), 'DependenteSearchView'),
    exact: true,
    path: '/mfp/dependenteSearchView',
  },
  {
    component: createLoadable(() => import('./dependente/DependenteEditView'), 'DependenteEditView'),
    exact: true,
    path: '/mfp/dependenteSearchView/edit',
  },
  {
    component: createLoadable(() => import('./calculoParametros/CalculoParametrosProcessView'), 'CalculoParametrosProcessView'),
    exact: true,
    path: '/mfp/calculoParametrosProcessView',
  },
  {
    component: createLoadable(() => import('../mfp/resilicao/ResilicaoSearchView'), 'ResilicaoSearchView'),
    exact: true,
    path: '/mfp/resilicaoSearchView',
  },
  {
    component: createLoadable(() => import('../mfp/resilicao/ResilicaoEditView'), 'ResilicaoEditView'),
    path: '/mfp/resilicaoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./fichaFinanceira/FichaFinanceiraEditView'), 'FichaFinanceiraEditView'),
    path: '/mfp/fichaFinanceiraEditView',
  },
  {
    component: createLoadable(() => import('./operacaoEmLote/OperacaoEmLoteProcessView'), 'OperacaoEmLoteProcessView'),
    exact: true,
    path: '/mfp/operacaoEmLoteProcessView',
  },
  {
    component: createLoadable(
      () => import('./report/termoAtividadeServicoEssencial/TermoAtividadeServicoEssencialReportView'),
      'TermoAtividadeServicoEssencialReportView'
    ),
    exact: true,
    path: '/mfp/termoAtividadeServicoEssencialReportView',
  },
  {
    component: createLoadable(() => import('./report/termoRescisao/TermoRescisaoReportView'), 'TermoRescisaoReportView'),
    exact: true,
    path: '/mfp/termoRescisaoReportView',
  },
  {
    component: createLoadable(() => import('./report/termoMP936/TermoMP936ReportView'), 'TermoMP936ReportView'),
    exact: true,
    path: '/mfp/termoMP936ReportView',
  },
  {
    component: createLoadable(() => import('./report/reciboProtocoloEsocial/ReciboProtocoloEsocialReportView'), 'ReciboProtocoloEsocialReportView'),
    exact: true,
    path: '/mfp/reciboProtocoloEsocialReportView',
  },
  {
    component: createLoadable(
      () => import('./trabalhadorQualificacaoCadastralExpo/TrabalhadorQualificacaoCadastralExpoProcessView'),
      'TrabalhadorQualificacaoCadastralExpoProcessView'
    ),
    exact: true,
    path: '/mfp/trabalhadorQualificacaoCadastralExpoProcessView',
  },
  {
    component: createLoadable(() => import('./report/cartaAdvertencia/CartaAdvertenciaReportView'), 'CartaAdvertenciaReportView'),
    exact: true,
    path: '/mfp/cartaAdvertenciaReportView',
  },
  {
    component: createLoadable(() => import('./consultaCalculos/ConsultaCalculosProcessView'), 'ConsultaCalculosProcessView'),
    exact: true,
    path: '/mfp/consultaCalculosProcessView',
  },
  {
    component: createLoadable(() => import('./monitorEventoEsocial/MonitorEventoEsocialProcessView'), 'MonitorEventoEsocialProcessView'),
    exact: true,
    path: '/mfp/monitorEventoEsocialProcessView',
  },
  {
    component: createLoadable(
      () => import('./sincronizacaoEventosEsocial/SincronizacaoEventosEsocialProcessView'),
      'SincronizacaoEventosEsocialProcessView'
    ),
    exact: true,
    path: '/mfp/sincronizacaoEventosEsocialProcessView',
  },
  {
    component: createLoadable(() => import('./importacaoXMLEventos/ImportacaoXMLEventosProcessView'), 'ImportacaoXMLEventosProcessView'),
    exact: true,
    path: '/mfp/importacaoXMLEventosProcessView',
  },
  {
    component: createLoadable(
      () => import('./importacaoXMLEventos/ImportacaoXMLEventosArquivosDigitaisProcessView'),
      'ImportacaoXMLEventosArquivosDigitaisProcessView'
    ),
    exact: true,
    path: '/mfp/importacaoXMLEventosArquivosDigitaisProcessView',
  },
  {
    component: createLoadable(() => import('./exportacaoGrrf/ExportacaoGrrfProcessView'), 'ExportacaoGrrfProcessView'),
    exact: true,
    path: '/mfp/exportacaoGrrfProcessView',
  },
  {
    component: createLoadable(() => import('./homolognet/HomologNetProcessView'), 'HomologNetProcessView'),
    exact: true,
    path: '/mfp/homologNetProcessView',
  },
  {
    component: createLoadable(() => import('./bolsaQualificacao/BolsaQualificacaoEditView'), 'BolsaQualificacaoEditView'),
    exact: true,
    path: '/mfp/bolsaQualificacaoEditView',
  },
  {
    component: createLoadable(
      () => import('./exportacaoBolsaQualificacao/BolsaQualificacaoExportacaoProcessView'),
      'BolsaQualificacaoExportacaoProcessView'
    ),
    exact: true,
    path: '/mfp/bolsaQualificacaoExportacaoProcessView',
  },
  {
    component: createLoadable(() => import('./report/filiacaoSindical/FiliacaoSindicalReportView'), 'FiliacaoSindicalReportView'),
    exact: true,
    path: '/mfp/filiacaoSindicalReportView',
  },
  {
    component: createLoadable(() => import('./report/comparativoEsocial/ComparativoEsocialReportView'), 'ComparativoEsocialReportView'),
    exact: true,
    path: '/mfp/comparativoEsocialReportView',
  },
  {
    component: createLoadable(() => import('./report/carteiraTrabalhoDigital/ListagemCarteiraReportView'), 'ListagemCarteiraReportView'),
    exact: true,
    path: '/mfp/listagemCarteiraReportView',
  },
  {
    component: createLoadable(() => import('./report/ocorrenciaEsocial/OcorrenciaEsocialReportView'), 'OcorrenciaEsocialReportView'),
    exact: true,
    path: '/mfp/ocorrenciaEsocialReportView',
  },
  {
    component: createLoadable(
      () => import('./report/atualizacaoDadosCadastrais/AtualizacaoDadosCadastraisReportView'),
      'AtualizacaoDadosCadastraisReportView'
    ),
    exact: true,
    path: '/mfp/atualizacaoDadosCadastraisReportView',
  },
  {
    component: createLoadable(() => import('./report/relacaoDependenteIRPF/RelacaoDependenteIRPFReportView'), 'RelacaoDependenteIRPFReportView'),
    exact: true,
    path: '/mfp/relacaoDependenteIRPFReportView',
  },
  {
    component: createLoadable(
      () => import('./vinculoEventoSimplificado/VinculoEventoSimplificadoProcessView'),
      'VinculoEventoSimplificadoProcessView'
    ),
    exact: true,
    path: '/mfp/vinculoEventoSimplificadoProcessView',
  },
  {
    component: createLoadable(
      () => import('./monitoramentoSaudeTrabalhador/MonitoramentoSaudeTrabalhadorSearchView'),
      'MonitoramentoSaudeTrabalhadorSearchView'
    ),
    exact: true,
    path: '/mfp/monitoramentoSaudeTrabalhadorSearchView',
  },
  {
    component: createLoadable(
      () => import('./monitoramentoSaudeTrabalhador/MonitoramentoSaudeTrabalhadorEditView'),
      'MonitoramentoSaudeTrabalhadorEditView'
    ),
    exact: true,
    path: '/mfp/monitoramentoSaudeTrabalhadorSearchView/edit',
  },
  {
    component: createLoadable(() => import('./exameToxicologico/ExameToxicologicoSearchView'), 'ExameToxicologicoSearchView'),
    exact: true,
    path: '/mfp/exameToxicologicoSearchView',
  },
  {
    component: createLoadable(() => import('./exameToxicologico/ExameToxicologicoEditView'), 'ExameToxicologicoEditView'),
    exact: true,
    path: '/mfp/ExameToxicologicoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./procedimentoRealizado/ProcedimentoRealizadoSearchView'), 'ProcedimentoRealizadoSearchView'),
    exact: true,
    path: '/mfp/procedimentoRealizadoSearchView',
  },
  {
    component: createLoadable(() => import('./condicaoAmbientalTrabalho/CondicaoAmbientalTrabalhoSearchView'), 'CondicaoAmbientalTrabalhoSearchView'),
    exact: true,
    path: '/mfp/condicaoAmbientalTrabalhoSearchView',
  },
  {
    component: createLoadable(() => import('./condicaoAmbientalTrabalho/CondicaoAmbientalTrabalhoEditView'), 'CondicaoAmbientalTrabalhoEditView'),
    exact: true,
    path: '/mfp/condicaoAmbientalTrabalhoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./agenteCausadorSituacaoGeradora/AgenteCausadorSearchView'), 'AgenteCausadorSearchView'),
    exact: true,
    path: '/mfp/agenteCausadorSearchView',
  },
  {
    component: createLoadable(() => import('./agenteCausadorSituacaoGeradora/SituacaoGeradoraSearchView'), 'SituacaoGeradoraSearchView'),
    exact: true,
    path: '/mfp/situacaoGeradoraSearchView',
  },
  {
    component: createLoadable(() => import('./parteCorpoAtingida/ParteCorpoAtingidaSearchView'), 'ParteCorpoAtingidaSearchView'),
    exact: true,
    path: '/mfp/parteCorpoAtingidaSearchView',
  },
  {
    component: createLoadable(() => import('./naturezaLesao/NaturezaLesaoSearchView'), 'NaturezaLesaoSearchView'),
    exact: true,
    path: '/mfp/naturezaLesaoSearchView',
  },
  {
    component: createLoadable(
      () => import('./agenteCausadorSituacaoGeradora/AgenteCausadorSituacaoGeradoraSearchView'),
      'AgenteCausadorSituacaoGeradoraSearchView'
    ),
    exact: true,
    path: '/mfp/agenteCausadorSituacaoGeradoraSearchView',
  },
  {
    component: createLoadable(() => import('./report/eventoReferencia/EventoReferenciaReportView'), 'EventoReferenciaReportView'),
    exact: true,
    path: '/mfp/eventoReferenciaReportView',
  },
  {
    component: createLoadable(() => import('./report/exameOcupacionalSST/ExamesOcupacionaisSSTReportView'), 'ExamesOcupacionaisSSTReportView'),
    exact: true,
    path: '/mfp/exameOcupacionalSSTReportView',
  },
  {
    component: createLoadable(() => import('./importacaoVinculo/ImportacaoVinculoProcessView'), 'ImportacaoVinculoProcessView'),
    exact: true,
    path: '/mfp/importacaoVinculoProcessView',
  },
  {
    component: createLoadable(() => import('./codigoTerceirosSuspenso/CodigoTerceirosSuspensoSearchView'), 'CodigoTerceirosSuspensoSearchView'),
    exact: true,
    path: '/mfp/codigoTerceirosSuspensoSearchView',
  },
  {
    component: createLoadable(
      () => import('./report/avisoDesligamentoJustaCausa/AvisoDesligamentoJustaCausaReportView'),
      'AvisoDesligamentoJustaCausaReportView'
    ),
    exact: true,
    path: '/mfp/avisoDesligamentoJustaCausaReportView',
  },
  {
    component: createLoadable(() => import('./processoTrabalhista/ProcessoTrabalhistaSearchView'), 'ProcessoTrabalhistaSearchView'),
    exact: true,
    path: '/mfp/processoTrabalhistaSearchView',
  },
  {
    component: createLoadable(() => import('./processoTrabalhista/ProcessoTrabalhistaEditView'), 'ProcessoTrabalhistaEditView'),
    exact: true,
    path: '/mfp/processoTrabalhistaSearchView/edit',
  },
  {
    component: createLoadable(
      () => import('./tributosProcessoTrabalhista/TributosProcessoTrabalhistaSearchView'),
      'TributosProcessoTrabalhistaSearchView'
    ),
    exact: true,
    path: '/mfp/tributosProcessoTrabalhistaSearchView',
  },
  {
    component: createLoadable(
      () => import('./tributosProcessoTrabalhista/TributosProcessoTrabalhistaEditView'),
      'TributosProcessoTrabalhistaEditView'
    ),
    exact: true,
    path: '/mfp/tributosProcessoTrabalhistaSearchView/edit',
  },
  {
    component: createLoadable(() => import('./calculoProvisao/CalculoProvisaoProcessView'), 'CalculoProvisaoProcessView'),
    exact: true,
    path: '/mfp/calculoProvisaoProcessView',
  },
  {
    component: createLoadable(() => import('./calculoProvisao/HistoricoProvisaoSearchView'), 'HistoricoProvisaoSearchView'),
    exact: true,
    path: '/mfp/historicoProvisaoSearchView',
  },
  {
    component: createLoadable(
      () => import('./vinculoEventoPlanoSaudeSimplificado/VinculoEventoPlanoSaudeSimplificadoProcessView'),
      'VinculoEventoPlanoSaudeSimplificadoProcessView'
    ),
    exact: true,
    path: '/mfp/vinculoEventoPlanoSaudeSimplificadoProcessView',
  },
  {
    component: createLoadable(
      () => import('./trabalhadorSalarioSimplificado/TrabalhadorSalarioSimplificadoProcessView'),
      'TrabalhadorSalarioSimplificadoProcessView'
    ),
    exact: true,
    path: '/mfp/trabalhadorSalarioSimplificadoProcessView',
  },
];

export default mfpRoutes;
