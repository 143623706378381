import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import {
  alertManager,
  AlertType,
  clearSearchDataGridStore,
  ColumnsProps,
  consultarSituacaoFalhaConexao,
  currentDate,
  dateColumn,
  environment,
  ERROR,
  FINANCIAL,
  GridTable,
  GridTableSortState,
  INFORMATION,
  progressActions,
  shortcutManagerHandlePageKeyAction,
  showToast,
  SUCCESS,
  useLocalStorage,
  userActions,
  useSessionStorage,
  WARNING,
} from 'summer';
import logoBox from '../../../assets/calima_box_icon.svg';
import logoConnect from '../../../assets/calima_connect_icon.svg';
import logoGer from '../../../assets/calima_ger_icon.svg';
import logoMcg from '../../../assets/calima_mcg_icon.svg';
import logoMcp from '../../../assets/calima_mcp_icon.svg';
import logoMfp from '../../../assets/calima_mfp_icon.svg';
import logoMho from '../../../assets/calima_mho_icon.svg';
import logoMic from '../../../assets/calima_mic_icon.svg';
import logoMlf from '../../../assets/calima_mlf_icon.svg';
import logoMpr from '../../../assets/calima_mpr_icon.svg';
import logoNitrus from '../../../assets/calima_nitrus_icon.svg';
import logoSite from '../../../assets/calima_site_icon.svg';
import useNetworkStatus from '../../../hooks/useNetworkStatus';
import useNotification from '../../../hooks/useNotification';
import { CertificadoVencidoParam } from '../../../models/dtos/ger/certificadoVencidoParam';
import { Notificacao } from '../../../models/dtos/ger/notificacao';
import {
  atualizarAtividadeSistema,
  atualizarGlobalParameter,
  checkSessaoValida,
  exibirRetrospectiva,
  findAllJobs,
  salvarUsuarioEmpresaAtual,
  verificarEmpCertifVencido,
} from '../../../services/ger.service';
import { Reducers } from '../../../store/ducks';
import {
  GlobalState,
  setGlobalParameter,
  setRetrospectiva,
  setTaExibindoMensageria,
  setTaExibindoPropaganda,
  setTaExibindoRetrospectiva,
  toggleMenuModulosMobile,
  toggleMobileSidebar,
} from '../../../store/ducks/global.duck';
import { JobState, setJobs } from '../../../store/ducks/jobs.duck';
import { setNotifications, updateNotification } from '../../../store/ducks/notifications.duck';
import { LOCAL_STORAGE_LOGIN_TOKEN, LOCAL_STORAGE_SOCKET_HASH } from '../../../utilities/constantes';
import { atualizarEmpresaGlobalParameter } from '../../../utilities/ger.util';
import {
  checkUniqueUserSession,
  efetuarLogoutWithMsg,
  getUniqueUserHash,
  pingSocketBeforeLogout,
  redirectToLogin,
} from '../../../utilities/login.util';
import { onSortGridTable } from '../../../utilities/sort.util';
import AlterarSenhaView from '../../pages/ger/alteracaoSenha/AlterarSenhaView';
import CertificadoVencidoView from '../../pages/ger/certificadoVencido/CertificadoVencidoView';
import MensageriaView from '../../pages/ger/mensageria/MensageriaView';
import ModalAdBlockAccess from '../../pages/ger/modalAdBlockAccess/ModalAdBlockAccess';
import ModalAdCalimaPro from '../../pages/ger/modalAdCalimaPro/ModalAdCalimaPro';
import ModalPesquisaNPS from '../../pages/ger/modalPesquisaNPS/ModalPesquisaNPS';
import ModalWellcomeCalimaPro from '../../pages/ger/modalWellcomeCalimaPro/ModalWellcomeCalimaPro';
import PropagandaView from '../../pages/ger/propaganda/PropagandaView';
import RetrospectivaView from '../../pages/ger/retrospectiva/RetrospectivaView';
import UsuarioValidarExistenciaCPF from '../../pages/ger/usuario/UsuarioValidarExistenciaCPF';
import ValidarEnderecoAdm from '../../pages/ger/validarEnderecoAdm/validarEnderecoAdm';
import ValidarEnderecoEmpresa from '../../pages/ger/validarEnderecoEmpresa/ValidarEnderecoEmpresa';
import ValidarTributacaoEmpresa from '../../pages/ger/validarTributacaoEmpresa/ValidarTributacaoEmpresa';
import ModalPropagandaIntegraContador from '../../pages/mlf/modalPropagandaIntegraContador/ModalPropagandaIntegraContador';
import Header from '../header/Header';
import JobInfoView from '../job/JobInfoView';
import MenuModulos from '../menuModulos/MenuModulos';
import Networks from '../networks/Networks';
import TourFavoritos from '../tourFavoritos/TourFavoritos';
import SidebarFooter from './SidebarFooter';
import SidebarHeader from './SidebarHeader';
import SidebarMenu from './SidebarMenu';
import { connect, disconnect } from './websocket';

import './sidebar.css';

const CONTAINER_STATUS_OK = 90;

const ICON_LABEL_MENU = {
  BOX: [logoBox, 'Box'],
  CONNECT: [logoConnect, 'Connect'],
  GER: [logoGer, 'Módulo Principal'],
  MCG: [logoMcg, 'Módulo Contábil'],
  MCP: [logoMcp, 'Módulo Controle Patrimonial'],
  MFP: [logoMfp, 'Módulo Folha de Pagamento'],
  MHO: [logoMho, 'Módulo de Honorários'],
  MHO_NITRUS: [logoNitrus, 'Nitrus/Honorários'],
  MIC: [logoMic, 'Integra Contador'],
  MLF: [logoMlf, 'Módulo Fiscal'],
  MPR: [logoMpr, 'Módulo de Protocolos'],
  SITE: [logoSite, 'Site'],
};

const Sidebar: FC = ({ children }) => {
  const {
    accessOldHonorarios,
    currentState,
    sidebarToggle,
    sidebarToggleMobile,
    headerSearchHover,
    headerDrawerToggle,
    globalParameter,
    taExibindoPropaganda,
    taExibindoRetrospectiva,
  } = useSelector<Reducers, GlobalState>(state => state.globalReducer);
  const { data: jobs } = useSelector<Reducers, JobState>(state => state.jobReducer);
  const [loginTokenStorage, writeLoginTokenStorage] = useLocalStorage(LOCAL_STORAGE_LOGIN_TOKEN);
  const [socketHashStorage] = useSessionStorage(LOCAL_STORAGE_SOCKET_HASH);
  const [mensageriaNotifications, setMensageriaNotifications] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const notificationHook = useNotification();
  const isOnline = useNetworkStatus();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const certificadoVencidoRef = useRef<CertificadoVencidoParam>();

  const bloquearMensageria = useRef(false);

  const codigoCliente = globalParameter && globalParameter.acesso ? globalParameter.acesso.codigoCliente : null;
  const usuario = globalParameter && globalParameter.usuario ? globalParameter.usuario : null;

  useEffect(() => {
    bloquearMensageria.current = taExibindoPropaganda || taExibindoRetrospectiva;
  }, [taExibindoPropaganda, taExibindoRetrospectiva]);

  useEffect(
    () => {
      exibirPropaganda();
      validarRetrospectiva();
      exibirCertificadoVencido();

      let isFinanceiroUser = false;
      let isConnectUser = false;
      let isBoxUser = false;

      if (usuario && usuario.acessoModulos) {
        isFinanceiroUser = usuario.administrador || usuario.acessoFinanceiro;
        isConnectUser = usuario.acessoModulos.connect;
        isBoxUser = usuario.acessoModulos.box;
      }

      const client = connect(codigoCliente);

      client.onWebSocketClose = () => pingSocketBeforeLogout(client, dispatch);

      client.onStompError = error => {
        alertManager.emit({
          message: error.headers.message,
          type: AlertType.INFORMATION,
        });
      };

      client.onConnect = () => {
        client.subscribe('/topic/notifications', message => {
          const novasNotificacoes = JSON.parse(message.body);
          const checkSocketHash = message.headers && (!message.headers.socketHash || socketHashStorage === message.headers.socketHash);

          if (Array.isArray(novasNotificacoes) && checkSocketHash) {
            const notificationType = message.headers.type;

            if (notificationType === 'mensagemController') {
              setMensageriaNotifications(novasNotificacoes);
              setTimeout(() => exibirMensageria(), 2000);
            } else {
              const isGeneralAlerts = notificationType === 'generalAlerts';
              const isFinanceiroAlerts = notificationType === 'financeiroAlerts';
              const isConnectAlerts = notificationType === 'connectAlerts';
              const isBoxAlerts = notificationType === 'boxAlerts';
              const isMensagemAlerts = notificationType === 'mensagensNotification';
              const isDownloadAlerts = notificationType === 'reportDownload' || notificationType === 'arquivoDigitalDownload';

              const isFinanceiro = isFinanceiroAlerts && isFinanceiroUser;
              const isConnect = isConnectAlerts && isConnectUser;
              const isBox = isBoxAlerts && isBoxUser;

              const isEspecialValido = isFinanceiro || isConnect || isBox;
              const alertEspecial = isFinanceiroAlerts || isConnectAlerts || isBoxAlerts;

              novasNotificacoes.forEach((novaNotificacao: Notificacao) => {
                if (isEspecialValido || !alertEspecial) {
                  const isInformation = novaNotificacao.status === 'information';
                  const isError = novaNotificacao.status === 'error';
                  const isSuccess = novaNotificacao.status === 'success';
                  const isWarning = novaNotificacao.status === 'warning';
                  if (isGeneralAlerts) {
                    if (isSuccess) {
                      showToast(novaNotificacao.mensagem, SUCCESS);
                    } else if (isInformation) {
                      showToast(novaNotificacao.mensagem, INFORMATION);
                    } else if (isWarning) {
                      showToast(novaNotificacao.mensagem, WARNING);
                    } else {
                      showToast('Ocorreu um erro ao realizar o processo.', ERROR);
                    }
                  }

                  if ((isDownloadAlerts && isInformation) || isMensagemAlerts) {
                    notificationHook.dispatchNotificationAction(novaNotificacao);
                  } else {
                    if (isFinanceiro) {
                      showToast(
                        novaNotificacao.mensagem,
                        FINANCIAL,
                        () => {
                          window.open(novaNotificacao.link, '_blank');
                        },
                        'Clique aqui para acessar!'
                      );
                    }
                    if (isError) {
                      showToast(novaNotificacao.mensagem, WARNING);
                      if (novaNotificacao.tipo === 'reportDownload' && novaNotificacao.paramMap && novaNotificacao.paramMap.reportFile) {
                        notificationHook.download(novaNotificacao.paramMap.reportFile);
                      }
                    }
                  }
                }

                if (novaNotificacao.atualizarGlobalParameter) {
                  atualizarGlobalParameter(
                    { id: globalParameter.usuario.id },
                    {
                      thenFunction: result => {
                        dispatch(setGlobalParameter(result));
                      },
                    }
                  );
                }
              });
            }
            dispatch(setNotifications({ novasNotificacoes, notificationType }));
          }
        });

        client.subscribe('/topic/notificationsTicket', message => {
          const novaNotificacao = JSON.parse(message.body);
          if (novaNotificacao && novaNotificacao.paramMap) {
            if (!novaNotificacao.paramMap.emailCliente || novaNotificacao.paramMap.emailCliente === globalParameter.usuario.email) {
              showToast(novaNotificacao.mensagem, INFORMATION);
              dispatch(setNotifications({ novasNotificacoes: [novaNotificacao], notificationType: 'alertas' }));
            }
          }
        });

        client.subscribe('/topic/jobs', message => {
          findAllJobs({ errorFunction: mensagem => showToast(mensagem, ERROR), thenFunction: result => dispatch(setJobs(result)) });
        });

        client.subscribe('/topic/jobUpdate', message => {
          dispatch(
            setJobs(
              jobs.map(it => {
                const result = it;
                if (result.id === Number(message.headers.jobId)) {
                  result.mensagem = message.body;
                }
                return result;
              })
            )
          );
          dispatch(updateNotification({ jobId: message.headers.jobId.toString(), message: message.body }));
        });

        client.subscribe('/topic/notifyDestruction', message => {
          const destruction = JSON.parse(message.body);
          if (destruction) {
            dispatch(userActions.logout());
            redirectToLogin();
          } else {
            alertManager.emit({
              message: 'Não detectamos utilização em seu servidor. Por motivo de segurança iremos desconectá-lo.',
              onOkClick: () => {
                atualizarAtividadeSistema({
                  errorFunction: mensagem => showToast(mensagem, ERROR),
                  thenFunction: () => {
                    showToast('Ok, recebemos sua resposta!', SUCCESS);
                  },
                });
              },
              textOKButton: 'Não desconectar, estou aqui!',
              type: AlertType.INFORMATION,
            });
          }
        });

        client.subscribe('/topic/warningUniqueUser', message => {
          if (!checkUniqueUserSession(message.body)) {
            redirectToLogin('LOGIN');
          }
        });

        client.subscribe('/topic/downloadFile', message => {
          if (message.headers.socketHash === socketHashStorage) {
            notificationHook.download(message.body);
          }
        });

        client.publish({ destination: '/app/pendingNotifications', body: socketHashStorage });
      };

      client.activate();

      document.addEventListener('visibilitychange', handleVisibilityChange, false);
      document.addEventListener('keydown', shortcutManagerHandlePageKeyAction, false);

      attachTranslatorObserver();

      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
        document.removeEventListener('keydown', shortcutManagerHandlePageKeyAction);
        disconnect();
      };
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => {
      if (!isOnline && environment.production) {
        alertManager.emit({
          message: 'Detectamos uma instabilidade em sua conexão com a internet. Deseja verificar sua conexão com nossos servidores?',
          onNoClick: () => {
            dispatch(userActions.logout());
            redirectToLogin();
          },
          onOkClick: () => {
            dispatch(
              progressActions.showProgress({
                animated: true,
                className: 'progress-bar-rounded progress-animated-alt',
                color: 'first',
                striped: true,
                value: 100,
              })
            );
            consultarSituacaoFalhaConexao({
              codigoCliente,
              errorFunc: () => {
                dispatch(progressActions.hideProgress());
                efetuarLogoutWithMsg('Não conseguimos comunicar com o servidor, verifique sua conexão com a internet e tente novamente.', dispatch);
              },
              resultFunc: (result, token) => {
                dispatch(progressActions.hideProgress());
                writeLoginTokenStorage(token);
                if (result && result.containerStatus === CONTAINER_STATUS_OK && result.urlSistema === environment.siteURL) {
                  showToast('Conexão Verificada com sucesso!', SUCCESS);
                } else {
                  efetuarLogoutWithMsg('Não conseguimos comunicar com o servidor, verifique sua conexão com a internet e tente novamente.', dispatch);
                }
              },
              tokenAcesso: loginTokenStorage,
            });
          },
          textWarningYes: 'Verificar Conexão',
          type: AlertType.WARNING_YES_NO,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOnline]
  );

  useEffect(
    () => {
      if (isOpen) {
        toggle();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location]
  );

  const attachTranslatorObserver = () => {
    const dom = document.querySelector('html');
    const observer = new MutationObserver(() => checkTranslator(dom));

    observer.observe(dom, { attributes: true, attributeFilter: ['class'], childList: false, characterData: false });
    checkTranslator(dom);
  };

  const checkTranslator = dom => {
    if (dom.classList.contains('translated-rtl') || dom.classList.contains('translated-ltr')) {
      setTimeout(() => {
        alertManager.emit({
          message: (
            <div className="text-justify px-2">
              Identificamos que você está utilizando um plugin incompatível com o Calima. Para que o sistema possa operar corretamente, siga as
              instruções descritas neste{' '}
              <a
                href="https://ajuda.calima.app/index.php?title=Problemas_com_Google_Tradutor"
                className="passo-a-passo"
                target="_blank"
                rel="noopener noreferrer"
              >
                passo-a-passo
              </a>
              .
            </div>
          ),
          onOkClick: () => checkTranslator(dom),
          type: AlertType.INFORMATION,
        });
      }, 500);
    }
  };

  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      const uData = JSON.parse(window.localStorage.getItem('u'));
      if (uData) {
        checkSessaoValida(
          {
            uniqueUserHash: getUniqueUserHash(),
            usuario: uData.usuario,
          },
          {
            errorFunction: mensagem => showToast(mensagem, ERROR),
            thenFunction: result => {
              if (!result) {
                redirectToLogin('LOGIN');
              }
            },
          }
        );
      }
    }
  };

  const exibirPropaganda = () => {
    const { acesso } = globalParameter;
    if (acesso && usuario) {
      if (!acesso.remocaoPropagandasHabilitado) {
        dispatch(setTaExibindoPropaganda(true));
        setTimeout(exibirPropaganda, 15 * 60 * 1000); // Disparada a cada 15 min
      }
    }
  };

  const exibirMensageria = () => {
    if (bloquearMensageria.current) {
      setTimeout(() => exibirMensageria(), 2000);
    } else {
      dispatch(setTaExibindoMensageria(true));
    }
  };

  const onSortCertificadoVencido = (lista, state: GridTableSortState) => onSortGridTable(state, lista);

  const certificadoVencidoColumns: ColumnsProps[] = [
    {
      dataField: 'razaoSocial',
      sort: true,
      text: 'Empresa',
    },
    dateColumn({
      dataField: 'vencimento',
      sort: true,
      text: 'Vencimento',
    }),
  ];

  const exibirCertificadoVencido = () => {
    verificarEmpCertifVencido(globalParameter.usuario.id, {
      errorFunction: mensagem => showToast(mensagem, ERROR),
      thenFunction: certificados => {
        if (certificados && certificados.length > 0) {
          alertCertificadoVencido(certificados);
        }
      },
    });
  };

  const onSelect = row => {
    certificadoVencidoRef.current = row;
  };

  const alertCertificadoVencido = certificados => {
    alertManager.emit({
      message: (
        <>
          <div className="d-flex align-items-center gap-10 mb-3 justify-content-center">
            Você tem um ou mais clientes com certificado digital vencido ou próximo do vencimento.
          </div>
          <div className="grid-certificado overflow-auto">
            <GridTable
              keyField="idCertificado"
              columns={certificadoVencidoColumns}
              onSelection={onSelect}
              data={certificados}
              onSort={onSortCertificadoVencido.bind(null, certificados)}
            />
          </div>
        </>
      ),
      onNoClick: () => {
        window.open('https://www.calimaerp.com/certificado_digital', '_blank');
      },
      onOkClick: () => {
        if (certificadoVencidoRef.current) {
          changeCurrentEmpresa(certificadoVencidoRef.current[0].idEmpresa);
        } else {
          goToCertificado();
        }
      },
      textWarningCancel: 'Resolver depois',
      textWarningNo: 'Renovar certificado agora!',
      textWarningYes: 'Corrigir agora',
      type: AlertType.WARNING_YES_NO_CANCEL,
    });
  };

  const changeCurrentEmpresa = useCallback(
    idEmpresaSelecionada => {
      const usuarioEmpresaAtual = {
        empresaAtual: { id: idEmpresaSelecionada },
        id: globalParameter.usuario.id,
      };

      salvarUsuarioEmpresaAtual(usuarioEmpresaAtual, {
        errorFunction: mensagem => {
          showToast(mensagem, ERROR);
        },
        thenFunction: result => {
          dispatch(setGlobalParameter(atualizarEmpresaGlobalParameter(globalParameter, result)));
          clearSearchDataGridStore();

          setTimeout(() => {
            goToCertificado();
          }, 500);
        },
      });
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentState, globalParameter]
  );

  const goToCertificado = () => {
    if (history.location.pathname === '/ger/certificadoDigitalView') {
      window.location.reload();
    } else {
      history.push('/ger/certificadoDigitalView');
    }
  };

  const validarRetrospectiva = () => {
    exibirRetrospectiva(globalParameter.usuario.id, {
      errorFunction: mensagem => showToast(mensagem, ERROR),
      thenFunction: res => {
        dispatch(setRetrospectiva(res));
        if (res.podeExibir && !res.jaVisualizou) {
          setTimeout(() => dispatch(setTaExibindoRetrospectiva({ modo: 1 })), 5000);
        }
      },
    });
  };

  const closeMobileSidebar = () => {
    if (sidebarToggleMobile) {
      dispatch(toggleMobileSidebar());
    }
  };

  const getIcon = () => {
    let result = ICON_LABEL_MENU.GER[0];
    if (currentState) {
      result = ICON_LABEL_MENU[currentState][0];
      if (globalParameter.acesso.temAcessoAoNitrus && accessOldHonorarios && currentState === 'MHO') {
        result = ICON_LABEL_MENU.MHO_NITRUS[0];
      }
    }
    return result;
  };

  const getLabel = () => {
    let result = ICON_LABEL_MENU.GER[1];
    if (currentState) {
      result = ICON_LABEL_MENU[currentState][1];
      if (globalParameter.acesso.temAcessoAoNitrus && accessOldHonorarios && currentState === 'MHO') {
        result = ICON_LABEL_MENU.MHO_NITRUS[1];
      }
    }
    return result;
  };

  const toggle = () => {
    setIsOpen(!isOpen);
    const isMobile = window.innerWidth <= 590;
    if (isMobile && !isOpen) {
      dispatch(toggleMobileSidebar());
      setTimeout(() => dispatch(toggleMenuModulosMobile()), 500);
    }
  };

  return (
    <>
      <div
        className={cx('app-wrapper app-sidebar-fixed app-header-fixed overflow-hidden', {
          'app-sidebar-collapsed': sidebarToggle,
          'app-sidebar-mobile-open': sidebarToggleMobile,
          'header-drawer-open': headerDrawerToggle,
          'search-wrapper-open': headerSearchHover,
        })}
      >
        <div className={`app-sidebar app-sidebar--dark app-sidebar--shadow sidebar-${currentState.toLowerCase()}`}>
          <SidebarHeader />
          <Dropdown isOpen={isOpen} toggle={toggle}>
            <DropdownToggle color="link" className="sidebar-header text-white sidebar-menu-modulos">
              <img
                src={getIcon()}
                alt="Módulo Icon"
                className="h5 mr-2"
                style={{ verticalAlign: 'top', width: 23, height: 23, filter: 'brightness(100)' }}
              />
              <span className="sidebar-menu-modulos-label">{getLabel()}</span>
              <div className={`btn-pill d-30 btn-modulo-${currentState.toLowerCase()}`}>
                <span className="btn-wrapper--icon">
                  <FontAwesomeIcon icon={['fas', 'chevron-right']} className="font-size-md" />
                </span>
              </div>
            </DropdownToggle>
            <DropdownMenu className="overflow-hidden border-0 bg-menu-modulos p-3 mt-2 dropdown-modulos">
              <MenuModulos />
            </DropdownMenu>
          </Dropdown>
          <div className="app-sidebar--content shadow-overflow">
            <SidebarMenu />
          </div>
          <SidebarFooter currentState={currentState} />
        </div>
        <div className="app-main" onClick={closeMobileSidebar}>
          <Header />
          <div className="app-content">
            <div className="app-content--inner">
              <div className="app-content--inner__wrapper">{children}</div>
            </div>
            <div className="app-footer text-black-50 app-footer--shadow d-flex">
              <Row className="w-100">
                <Col className="d-flex align-items-center">
                  <a href="https://www.calimaerp.com/" target="_blank" rel="noopener noreferrer">
                    Calima ERP Contábil
                  </a>{' '}
                  &copy; {currentDate().getFullYear()}
                </Col>
                <Col xl={5} lg={5} className="d-flex justify-content-center">
                  <Networks className="networks-footer" />
                </Col>
                <Col className="d-flex justify-content-end align-items-center">
                  <div className="d-inline-block powered-by">
                    <span className="d-inline-block mr-1">Desenvolvido por</span>
                    <a href="https://www.projetusti.com.br/" target="_blank" rel="noopener noreferrer">
                      Projetus TI
                    </a>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
      <MensageriaView notifications={mensageriaNotifications} />
      <PropagandaView />
      <RetrospectivaView />
      <ValidarEnderecoAdm />
      <JobInfoView />
      <AlterarSenhaView />
      <UsuarioValidarExistenciaCPF />
      <ValidarEnderecoEmpresa />
      <ValidarTributacaoEmpresa />
      <ModalAdBlockAccess />
      <ModalAdCalimaPro />
      <ModalWellcomeCalimaPro />
      <ModalPropagandaIntegraContador />
      <ModalPesquisaNPS />
      <CertificadoVencidoView />
      <TourFavoritos />
    </>
  );
};

export default Sidebar;
